import { Box, Card, CardHeader, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CardList } from '../base/CardList';
import { useSiteTemplate } from 'api/site';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDate } from 'utils/dateFormat';
import { CopyButton } from './CopyButton';
import { BackButton } from './BackButton';
import { linkHelper } from 'linkHelper';

export default function ViewSiteTemplate() {
  const { t } = useTranslation();
  const { templateId = '' } = useParams<{ templateId: string }>();
  const { data, isLoading } = useSiteTemplate(templateId);
  const navigate = useNavigate();

  const themes = (data?.data.result?.config?.wordpress?.themes ?? []).map((item, index) => ({
    ...item,
    id: index,
  }));

  const plugins = (data?.data.result?.config?.wordpress?.plugins ?? []).map((item, index) => ({
    ...item,
    id: index,
  }));

  const items = [
    {
      id: t('template_name'),
      value: data?.data.result?.name ?? '-',
      enableCopy: true,
    },
    {
      id: t('created'),
      value: data?.data.result?.created_at
        ? formatDate({
            date: data?.data.result?.created_at,
            dateStyle: 'short',
            timeStyle: 'short',
          })
        : '-',
      enableCopy: false,
    },
    {
      id: t('updated'),
      value: data?.data.result?.updated_at
        ? formatDate({
            date: data?.data.result?.updated_at,
            dateStyle: 'short',
            timeStyle: 'short',
          })
        : '-',
      enableCopy: false,
    },
    {
      id: t('original_domain'),
      value: data?.data.result?.created_from?.domain ?? '-',
      enableCopy: true,
    },
    {
      id: t('wordpress_version'),
      value: data?.data.result?.config?.wordpress?.version ?? '-',
      enableCopy: false,
    },
  ];
  return (
    <Stack gap={4}>
      <BackButton to={linkHelper.newDesign.settings.siteTemplates.getLink()} />
      <Box
        sx={{
          display: 'grid',
          gap: 'var(--dashboard-gap)',
          gridTemplateColumns: {
            sm: '1fr',
            lg: '1fr 1fr',
          },
        }}
      >
        <Card>
          <CardHeader title={t('template_information')} />
          <CardList
            loading={isLoading}
            items={items}
            renderItem={item => (
              <Stack>
                <Typography variant="body2" color="greys.500">
                  {item.id}
                </Typography>
                <Typography variant="body2" fontWeight={600}>
                  {item.value}
                </Typography>
              </Stack>
            )}
            renderAction={item =>
              item.enableCopy ? <CopyButton value={item.value || ''} /> : null
            }
          />
        </Card>
        <Stack gap={'var(--dashboard-gap)'}>
          <Card>
            <CardHeader
              title={t('themes_installed_count', {
                count: data?.data.result?.config?.wordpress?.themes?.length ?? 0,
              })}
            />
            <CardList
              loading={isLoading}
              items={themes}
              minHeightItems={themes.length}
              renderItem={item => (
                <Stack>
                  <Typography variant="body2" color="greys.500">
                    {item.name}
                  </Typography>
                </Stack>
              )}
              renderAction={() => null}
            />
          </Card>
          <Card>
            <CardHeader
              title={t('plugins_installed_count', {
                count: data?.data.result?.config?.wordpress?.plugins?.length ?? 0,
              })}
            />
            <CardList
              loading={isLoading}
              items={plugins}
              minHeightItems={plugins.length}
              renderItem={item => (
                <Stack>
                  <Typography variant="body2" color="greys.500">
                    {item.name}
                  </Typography>
                </Stack>
              )}
              renderAction={() => null}
            />
          </Card>
        </Stack>
      </Box>
    </Stack>
  );
}
